import $ from 'jquery';
import 'jquery-mask-plugin';

$(document).on('init', function (event) {
  let target = $(event.target);

  target.find('.mask_phone').mask('8 (000) 000-00-00', {
    placeholder: '8 (___) ___-__-__'
  })
})

$(document).on('pjax:end', function (event) {
  $(event.target).trigger('init');
})

$(function() {
  $(document).trigger('init');
})
